
import { Options, Vue } from 'vue-class-component'
import CardBase from '@/components/common/layout/CardBase.vue'
import { namespace } from 'vuex-class'
import ProviderItem from '@/types/providers/ProviderItem'
import formattersMixin from '@/mixins/formatters'
const providers = namespace('providers')
@Options({
  name: 'ProvidersList',
  mixins: [formattersMixin],
  components: {
    CardBase
  }
})
export default class ProviderList extends Vue {
  @providers.Action
  getProviders!: () => Promise<void>

  @providers.Getter
  providers!: ProviderItem[]

  mounted() {
    this.getProviders()
  }
}
